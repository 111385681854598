.about {
    height: 100%;
    .teddy-full {
        font-size: 55px;
        padding-top: 2% !important;
        padding: 10px 20px;
        color: white;
        border-style: solid;
        border-width: 0px 0px 1px;
        font-family: "Nanum Myeongjo";
        border-color: #292e3a;
    }
    .teddy-cool {
        display: flex;
        flex-direction: row;
        padding: 4% 40px;
        justify-content: center;
        width: 100%;
        align-items: center;
        label{
            font-size: 35px;
            font-family: "Nanum Myeongjo";
            color: white;
        }
        p {
            color: rgb(247, 248, 251);
            font-family: poppins;
            width: 61%;
            display: flex;
            flex-direction: column;
        }
        p.second{
          width: 100%;;  
        }
        span{
            display: flex;
            flex-direction: column;
        }
        img {
            height: 450px;
            width: 361px;
            object-fit: cover;
            margin-left: 26px;
        }
    }
    .teddy-info {
        .area-sh {
            font-size: 35px;
            padding-top: 2% !important;
            padding: 10px 20px;
            color: white;
            border-style: solid;
            border-width: 0px 0px 1px;
            font-family: "Nanum Myeongjo";
            border-color: #292e3a;
        }
        .area-of-sh {
            padding: 20px 0px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            .area-content {
                display: flex;
                flex-direction: column;
                color: white;
                width: 30.3%;
                margin-left: 2%;
                margin-bottom: 1%;
                label {
                    font-weight: 600;
                    font-size: 19px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    .about {
        .teddy-cool {
            display: flex;
            flex-direction: column-reverse;
            img {
                margin-bottom: 2%;
                width: 100%;
            }
            p {
                width: 100%;
            }
        }
        .teddy-info {
            .area-of-sh {
                padding: 0 10px;
                display: flex;
                flex-direction: column;
                .area-content {
                    width: 95%;
                }
            }
        }
    }
}